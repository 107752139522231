<template>
    <div>
        <mercur-dialog data-e2e="orderlineAddComplaintModal" :is-open.sync="isDialogOpen" width="850px">
            <div class="d-flex align-items-center">
                <h3 class="flex-grow-1 font-weight-normal mt-1">Add new complaint for this orderline</h3>
                <div>
                    <mercur-select v-model="parentComplaint">
                        <template #label>Add to complaints group</template>
                        <option v-for="complaint in complaints" :value="complaint.complaintId" :key="complaint.complaintId">
                            {{ complaint.complaintDepartment}} - {{ complaint.description }}
                        </option>
                    </mercur-select>
                </div>
            </div>
            <div data-e2e="orderlineAddComplaintDepartments" class="border px-3 py-2 mb-3" :class="getValidationClass($v, 'complaintTypes')">
                <h4 class="mb-2 mt-1 font-weight-normal"><label>Complaint departments</label></h4>
                <template v-for="(department, departmentKey) in items">
                    <span :key="departmentKey" class="mr-3" v-if="departmentKey !== 'CUSTOMER'">
                        <mercur-checkbox class="mb-2" :id="`lab-${department.title}`" :disabled="loading" v-model="form.complaintDepartments" :value="departmentKey" :key="departmentKey">{{ department.title }}</mercur-checkbox>
                    </span>
                </template>
                <span class="form-error" v-if="$v.form.$dirty && !$v.form.complaintDepartments.required">Complaint department is required.</span>
            </div>
            <div data-e2e="orderlineAddComplaintComplaintTypeSelector" class="border px-3 pt-3 pb-1 mb-3" v-if="form.complaintDepartments.length">
                <div class="row">
                    <div class="col-6" v-for="complaintDepartment in form.complaintDepartments" :key="complaintDepartment">
                        <mercur-select v-model="form.complaintTypes[complaintDepartment]" :class="getValidationClass($v, 'complaintTypes')">
                            <template #label>Complaint Type for {{ complaintDepartment }}</template>
                            <option v-for="(item, index) in items[complaintDepartment].items" :value="item" :key="`item - ${index}`">{{ item }}</option>
                            <template #note>
                                <span class="form-error" v-if="$v.form.$dirty && !$v.form.complaintTypes.isForAllComplaints">Complaint types are required for all departments.</span>
                            </template>
                        </mercur-select>
                    </div>
                </div>
            </div>
            <mercur-input v-if="form.complaintDepartments.includes('SUPPLIER')" min="0" max="100" v-model="form.supplierComplaintCostPercentage">
                Supplier complaint cost percentage
                <template #suffix>%</template>
            </mercur-input>
            <mercur-select :disabled="loading" v-model="form.respondent" v-if="form.complaintDepartments.includes('CUSTOMER_SERVICE') || form.complaintDepartments.includes('ARTWORK_CHECK')" :class="getValidationClass($v, 'respondent')">
                <template #label>Respondent</template>
                <option v-for="agent in agents" :key="agent.accountId" :value="`${agent.firstName} ${agent.lastName}`">{{ agent.firstName }} {{ agent.lastName }}</option>
                <template #note>
                    <span class="form-error" v-if="!$v.form.respondent.required">Respondent is required.</span>
                </template>
            </mercur-select>
            <mercur-textarea data-e2e="orderlineAddComplaintComplaintDescription" :disabled="loading" v-model="form.description" :class="getValidationClass($v, 'description')">
                Complaint Description
                <template #note>
                    <span class="form-error" v-if="!$v.form.description.required">Complaint description is required.</span>
                </template>
            </mercur-textarea>
            <template #footer>
                <mercur-button class="btn" @click.native="isDialogOpen = false" :disabled="loading">Cancel</mercur-button>
                <mercur-button data-e2e="orderlineAddComplaintCreateComplaintButton" class="btn btn-primary" type="submit" :disabled="loading" @click.native="submitForm">Create complaint</mercur-button>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import { required, requiredIf } from 'vuelidate/lib/validators'
import items from '@/langs/complaints.json'
import complaintSolutions from '@/langs/complaintSolutions.json'
import CONFIG from '@root/config'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'AddComplaint',
    mixins: [ FormMixin ],
    data () {
        return {
            isDialogOpen: false,
            form: {
                complaintDepartments: [],
                complaintTypes: {},
            },
            items: items,
            complaintSolutions,
            isCurrency: true,
            parentComplaint: null,
        }
    },
    props: {
        agents: {
            default: () => [],
        },
        complaints: {
            default: () => [],
        },
    },
    computed: {
        ...mapState('order', ['order', 'orderLine']),
    },
    methods: {
        ...mapActions('order', ['addComplaintToOrderLine']),
        submitForm () {
            const payload = {
                orderId: this.order.orderId,
                complaint: {
                    ...this.form,
                    orderLineId: this.orderLine.orderLineId,
                },
            }

            if (this.parentComplaint) {
                const parent = this.complaints.find(e => e.complaintId === this.parentComplaint)
                payload.complaint.parentComplaintId = parent.parentComplaintId
            }

            const url = CONFIG.API.ROUTES.COMPLAINTS.ADD.replace('{accountId}', this.$route.params.accountId)
            this.submit(url, payload).then(({ data }) => {
                if (Array.isArray(data)) {
                    data.forEach((item) => {
                        if (item.event === 'ComplaintWasAdded') {
                            this.addComplaintToOrderLine(item.response.data.complaint)
                        }
                    })
                }
                this.isDialogOpen = false
                this.parentComplaint = null
                this.form = {
                    complaintDepartments: [],
                    complaintTypes: {},
                }
                this.$root.$emit('notification:global', {
                    message: 'Complaint was added',
                })
            })
        },
    },
    validations: {
        form: {
            complaintDepartments: {
                required,
            },
            complaintTypes: {
                isForAllComplaints: function (value) {
                    return Object.keys(value).length === this.form.complaintDepartments.length
                },
            },
            description: {
                required,
            },
            respondent: {
                required: requiredIf(function () {
                    const arr = ['CUSTOMER_SERVICE', 'ARTWORK_CHECK']
                    let needsRespondent = false
                    arr.forEach(status => {
                        if (this.form.complaintDepartments.includes(status)) {
                            needsRespondent = true
                        }
                    })
                    return needsRespondent
                }),
            },
        },
    },
}
</script>
