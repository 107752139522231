<template>
    <mercur-card class="mb-4">
        <h3 class="font-weight-normal">Conversation</h3>
        <ul>
            <li v-for="(value, index) in conversation" :key="index"><strong>{{ value.author }}:</strong> {{ value.note }} <span>({{ value.dateCreated }})</span></li>
        </ul>
        <form data-e2e="complaintConversationForm" @submit.prevent="submitForm" v-if="validStatuses.includes(form.complaintStatus)">
            <mercur-textarea data-e2e="complaintConversationFormTextareaNote" v-model="note" :disabled="isLocked" required>
                Add merchant note
            </mercur-textarea>
            <mercur-button data-e2e="complaintConversationFormSubmitButton" class="btn btn-primary" type="submit" :disabled="loading || isLocked">Add note and send to {{ supplierDepartments.includes(form.complaintDepartment) ? 'virtual supplier' : 'customer support' }}</mercur-button>
        </form>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { setComplaintPayload } from '@/utils/payloadHelper'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
    name: 'ComplaintConversation',
    mixins: [ FormMixin ],
    props: {
        form: {
            default: null,
        },
        $v: {
            type: Object,
        },
        isLocked: {
            default: false,
        },
    },
    data () {
        return {
            note: '',
            validStatuses: ['NEW', 'OPEN', 'AWAITING_CUSTOMER_SERVICE'],
            supplierDepartments: ['SUPPLIER', 'CARRIER', 'SUPPLY_CHAIN', 'ARTWORK'],
            csDepartments: ['CUSTOMER_SERVICE', 'FINANCE', 'ARTWORK_CHECK', 'WEBSITE', 'CUSTOMER'],
        }
    },
    computed: {
        conversation () {
            let data = []
            if (Array.isArray(this.form.merchantComplaintNotes)) {
                data = [
                    ...this.form.merchantComplaintNotes.map((e) => {
                        return {
                            ...e,
                            author: 'Merchant',
                        }
                    }),
                ]
            }

            if (Array.isArray(this.form.virtualSupplierComplaintNotes)) {
                data = [
                    ...data,
                    ...this.form.virtualSupplierComplaintNotes.map((e) => {
                        return {
                            ...e,
                            author: 'Virtual Supplier',
                        }
                    }),
                ]
            }

            return data.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
        },
    },
    methods: {
        ...mapActions('order', ['updateComplaint']),
        submitForm () {
            const action = CONFIG.API.ROUTES.COMPLAINTS.UPDATE.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.$route.params.orderId)
            let payload = JSON.parse(JSON.stringify(this.form))
            if (payload.merchantComplaintNotes.constructor === Object) {
                payload.merchantComplaintNotes = []
            }

            payload.merchantComplaintNotes.push({
                dateCreated: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                note: this.note,
                uploadedBy: this.$store.state.auth.user.accountId,
                recipients: ['VIRTUAL_SUPPLIER'],
            })

            if (this.csDepartments.includes(payload.complaintDepartment)) {
                this.$set(payload, 'complaintStatus', 'AWAITING_CUSTOMER_SERVICE')
            }

            if (this.supplierDepartments.includes(payload.complaintDepartment)) {
                this.$set(payload, 'complaintStatus', 'AWAITING_SUPPLIER')
            }

            payload = setComplaintPayload(payload)

            if (payload.complaintDepartment !== 'CUSTOMER') {
                delete payload.complaintCosts
            }

            if (this.$v.$error) {
                this.$root.$emit('notification:global', {
                    message: 'Complaint form is not completed',
                })
            }

            this.submit(action, payload).then((data) => {
                this.$emit('updated', data.data.complaint)
                this.updateComplaint(data.data.complaint)
                this.note = ''
                this.$root.$emit('notification:global', {
                    message: 'Merchant note was added',
                })
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    ul {
        list-style: none;
        padding: 0;

        li {
            padding: 4px 0;
            white-space: pre-line;

            span {
                font-size: 11px;
            }
        }
    }
</style>
