export const setComplaintPayload = (payload) => {
    const filter = [
        'complaintId', 'orderLineId', 'complaintStatus', 'complaintDepartment', 'complaintAttachments', 'buyerComplaintNotes', 'merchantComplaintNotes',
        'supplierComplaintNotes', 'description', 'shareWithVirtualSupplier', 'complaintCosts', 'complaintType', 'complaintCategory', 'complaintSolution', 'respondent',
    ]
    return filterPayload(payload, filter)
}

export const filterPayloadForCustomerComplaintSolution = (payload) => {
    const filter = [
        'parentComplaintId', 'orderLineId', 'complaintDepartment', 'description', 'complaintCosts', 'complaintType', 'complaintSolution',
    ]
    return filterPayload(payload, filter)
}

export const setOrderLinePayload = (payload) => {
    const filter = [
        'sourceId', 'productPrice', 'shippingPrice', 'deliveryInfo', 'product',
    ]
    return filterPayload(payload, filter)
}

export const setUpdateOrderLinePayload = (payload) => {
    const filter = [
        'sourceId', 'productPrice', 'shippingPrice', 'deliveryInfo', 'product', 'orderLineNumber', 'orderLineId', 'orderId',
    ]
    return filterPayload(payload, filter)
}

export const setCustomerUpdatePayload = (payload) => {
    const filter = [
        'firstName', 'lastName', 'email', 'secondaryEmail', 'phone', 'phoneMobile', 'accountSettings', 'roles', 'language', 'countryCode',
    ]
    return filterPayload(payload, filter)
}

function filterPayload (payload, filter) {
    let result = {}
    filter.forEach((key) => {
        result[key] = payload[key]
    })

    return result
}
