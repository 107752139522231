<template>
    <div>
        <mercur-card class="c-complaints-list mb-4">
            <div class="d-flex align-items-center mb-2">
                <h3 class="m-0 flex-grow-1 font-weight-normal">List of complaints for {{ orderLine.orderLineNumber }}</h3>
                <mercur-button data-e2e="orderlineAddComplaintButton" class="btn btn-yellow" @click.native="$refs.add.isDialogOpen = true"  :disabled="isOrderLineCancelled">Add complaint</mercur-button>
            </div>
            <template v-if="complaintList.length">
                <merchant-data-table style="min-height: unset;" :options="options" :attrs="{'data-e2e':'complaintListTable'}" :items="complaintList" ref="table"></merchant-data-table>
            </template>
            <p v-else>There are no complaints for this orderline</p>
        </mercur-card>

        <add-complaint ref="add" :agents="agents" :complaints="complaintList"></add-complaint>
        <mercur-dialog :is-open.sync="isRefund">
            <h3>Are you sure you want to refund cost based on this complaint?</h3>
            <p v-if="hasComplaintCost">Cost that will be refunded is
                    <currency :value="draft.complaintCosts.value" :type="draft.complaintCosts.currency" />
                    (with VAT.
                    <strong>
                        <currency :value="vatComplaintCost" :type="draft.complaintCosts.currency"/>
                    </strong>)
                </p>
            <p v-else class="u-error">It seems that this complaint doesn't have complaint cost!</p>
            <template #footer>
                <mercur-button class="btn" @click.native="isRefund = false" :disabled="loading">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click="refundComplaint" :disabled="loading || !hasComplaintCost">Refund</mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="showReprintDialog" width="70vw" height="90vh">
            <h2 class="font-weight-normal">
                Reprint orderline
                <mercur-select v-model="orderCountry" class="c-add-order__currency form-invalid" v-if="!order.countryCode">
                    <template #label>Order currency and shop country</template>
                    <option v-for="(value, key) in currencyList" :key="key" :value="key">{{ value.currency }} ({{ key }})</option>
                </mercur-select>
            </h2>
            <div class="row">
                <div class="col-sm-8">
                    <div v-if="orderCountry">
                        <funnel ref="funnel"
                                @productAdded="addReprint"
                                :isShippingOverwritten="hasFreeShipping"
                                :isPriceEditable="true"
                                :presetProduct="presetProduct"
                                :country="orderCountry"
                        >
                        </funnel>
                        <alert type="danger" v-if="hasCountryCurrencyError">Country of the selected address does not match selected currency for the order!</alert>
                    </div>
                </div>
                <div class="col-sm-4">
                    <pretty-select
                        v-if="addresses.length"
                        label="label"
                        placeholder="Select delivery address for orderline"
                        :options="addresses"
                        v-model="selectedDeliveryAddress"
                    />
                </div>
            </div>
        </mercur-dialog>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CONFIG from '@root/config'
import AddComplaint from '@/components/elements/order/AddComplaint'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import StatusChip from '@/components/utils/StatusChip'
import Funnel from '../Funnel'
import Alert from '../Alert'
import PrettySelect from '../../utils/PrettySelect'
import HelperMethods from '../../utils/HelperMethods'
import Currency from '@/components/elements/Currency'

export default {
    name: 'ComplaintsList',
    mixins: [HelperMethods],
    components: { AddComplaint, MerchantDataTable, Funnel, Alert, PrettySelect, Currency },
    data () {
        return {
            editableComplaint: null,
            options: {
                columns: {
                    'Department': {
                        field: 'complaintDepartment',
                    },
                    'Description': { field: 'description', width: 400 },
                    'Status': { field: 'complaintStatus', cellRendererFramework: StatusChip },
                },
                actions: {
                    items: [
                        {
                            icon: 'far fa-clipboard',
                            tooltip: 'Make reprint',
                            onClick: (params) => {
                                this.triggerReprint(params.data)
                            },
                            isDisabled: ({ data }) => {
                                return data.complaintDepartment !== 'CUSTOMER' || !this.order.invoice || ['REPRINTED', 'REFUNDED'].includes(data.complaintStatus)
                            },
                            isHidden: ({ data }) => {
                                const reprintSolutions = ['reprint, partly', 'reprint, wait for return', 'instant reprint, proof via photo']
                                if (data.complaintSolution && !reprintSolutions.includes(data.complaintSolution.toLowerCase())) {
                                    return true
                                }
                                return data.complaintDepartment !== 'CUSTOMER'
                            },
                            attrs: {
                                'data-e2e': 'complaintFixMakeReprint',
                            },
                        },
                        {
                            icon: 'fas fa-dollar-sign',
                            tooltip: 'Make a refund',
                            onClick: (params) => {
                                this.triggerRefund(params.data)
                            },
                            isDisabled: ({ data }) => {
                                return data.complaintDepartment !== 'CUSTOMER' || !this.order.invoice || ['REPRINTED', 'REFUNDED'].includes(data.complaintStatus)
                            },
                            isHidden: ({ data }) => {
                                if (data.complaintSolution && !this.refundSolutions.includes(data.complaintSolution.toLowerCase())) {
                                    return true
                                }
                                return data.complaintDepartment !== 'CUSTOMER'
                            },
                            attrs: {
                                'data-e2e': 'complaintFixMakeRefund',
                            },
                        },
                        {
                            icon: 'fas fa-th',
                            tooltip: 'View details',
                            onClick: (params) => {
                                this.setEditableComplaint(params.data)
                            },
                            attrs: {
                                'data-e2e': 'complaintViewDetails',
                            },
                        },
                        {
                            icon: 'fas fa-plus',
                            tooltip: 'Add child complaint',
                            onClick: (params) => {
                                this.triggerAddChildComplaint(params.data)
                            },
                            attrs: {
                                'data-e2e': 'complaintAddChildComplaint',
                            },
                        },
                    ],
                },
                pagination: false,
                rowClassRules: {
                    'attached-complaint': (params) => {
                        const previousRowIndex = params.rowIndex - 1
                        if (!this.complaintList[previousRowIndex]) {
                            return false
                        }

                        const parentComplaintId = this.complaintList[previousRowIndex].parentComplaintId
                        return parentComplaintId && parentComplaintId === params.data.parentComplaintId
                    },
                },
            },
            isRefund: false,
            draft: {},
            orderCountry: null,
            presetProduct: null,
            hasCountryCurrencyError: false,
            selectedDeliveryAddress: null,
            showReprintDialog: false,
            draftOrderLine: null,
            loading: false,
        }
    },
    props: {
        agents: {
            default: () => [],
        },
        refundSolutions: {},
    },
    computed: {
        ...mapState('order', ['order', 'orderLine']),
        ...mapState('customer', ['customer', 'addresses']),
        ...mapState('shop', ['shops']),
        ...mapGetters('shop', ['workingCountriesList']),
        isOrderLineCancelled () {
            return this.orderLine.orderLineStatus === 'CANCELLED'
        },
        currencyList () {
            if (this.selectedShop && this.selectedShop.applicationId === '0') {
                const orderShop = this.shops.find(e => e.applicationId === this.$route.params.applicationId)
                if (!orderShop || !orderShop.configuration || !orderShop.configuration.workingCountries) {
                    return {}
                }

                return orderShop.configuration.workingCountries
            }

            return this.workingCountriesList
        },
        hasFreeShipping () {
            if (!this.order) {
                return false
            }

            if (this.order.freeShipping) {
                return true
            }

            if (this.order.discounts && this.order.discounts.some((e) => e.discountType === 'FREE_SHIPPING')) {
                return true
            }
            return false
        },
        hasComplaintCost () {
            if (!this.draft.complaintCosts || !Object.keys(this.draft.complaintCosts).length) {
                return false
            }

            return !!this.draft.complaintCosts.value
        },
        vatComplaintCost () {
            return this.draft.complaintCosts.value * (1 + this.orderLine.vat)
        },
        complaintList () {
            if (!this.orderLine || !this.orderLine.complaints || !Array.isArray(this.orderLine.complaints)) {
                return []
            }

            const sortedComplaints = []
            const usedIds = []
            this.orderLine.complaints.forEach(complaint => {
                if (usedIds.includes(complaint.parentComplaintId)) {
                    return
                }
                usedIds.push(complaint.parentComplaintId)
                sortedComplaints.push(
                    ...this.orderLine.complaints.filter(e => e.parentComplaintId === complaint.parentComplaintId)
                )
            })

            return sortedComplaints
        },
    },
    methods: {
        ...mapActions('order', ['setOrder', 'updateComplaint']),
        setEditableComplaint (complaint) {
            this.$set(this, 'editableComplaint', complaint)
            this.$emit('edit', complaint)
        },
        triggerRefund (data) {
            this.draft = data
            this.isRefund = true
        },
        refundComplaint () {
            if (!this.draft.complaintCosts.value) {
                this.$root.$emit('notification:global', {
                    message: 'Complaint cost is required',
                    type: 'error',
                })
            }

            const url = CONFIG.API.ROUTES.COMPLAINTS.REFUND.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.$route.params.orderId)
            this.loading = true
            this.post(url, this.draft, 'Complaint was refunded').then((data) => {
                this.draft = {}
                this.isRefund = false
                this.updateComplaint(data.data.complaint)
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
            })
        },
        triggerReprint (complaint) {
            this.draft = complaint
            const data = this.orderLine
            this.showReprintDialog = true
            this.draftOrderLine = JSON.parse(JSON.stringify(data))
            let attrs = {}
            data.product.attributes.forEach((attribute) => {
                attrs[attribute.attributeName] = attribute.attributeOption
            })
            data.product.attributes = attrs
            this.$set(this, 'presetProduct', JSON.parse(JSON.stringify(data.product)))
        },
        triggerAddChildComplaint (complaint) {
            this.$refs.add.parentComplaint = complaint.complaintId
            this.$refs.add.isDialogOpen = true
        },
        addReprint (product) {
            if (!this.selectedDeliveryAddress) {
                this.$root.$emit('notification:global', {
                    message: 'Please select delivery address',
                    type: 'error',
                })
                this.$refs.funnel.isAddingProduct = false
                return
            }

            if (!this.checkIfMatchesOrderCurrency(this.selectedDeliveryAddress)) {
                this.hasCountryCurrencyError = true
                this.$refs.funnel.isAddingProduct = false
                return
            }

            let productVatProfile = null
            if (product.vatProfile) {
                productVatProfile = product.vatProfile
                delete product.vatProfile
            }

            const url = CONFIG.API.ROUTES.ORDERS.GET_VARIATION
            const payload = {
                product: product,
                productPrice: product.prices.currencySpecificPrices[this.order.currency].salesPrice,
                shippingPrice: product.prices.currencySpecificPrices[this.order.currency].shippingPrice,
                currency: this.$store.getters['shop/calculatedCurrency'](this.orderCountry),
            }

            this.post(url, payload).then((data) => {
                const orderLine = {
                    product: data.data,
                    orderLineId: this.$uuid.v4(),
                    productPrice: product.productPrice,
                    shippingPrice: product.shippingPrice,
                    deliveryInfo: data.data.deliveryInfo,
                }

                if (productVatProfile) {
                    orderLine.vatProfile = productVatProfile
                }

                orderLine.deliveryInfo.address = this.selectedDeliveryAddress
                this.$delete(orderLine.deliveryInfo.address, 'totalRows')

                if (!this.checkShipsTo(orderLine)) {
                    return
                }

                this.reprint(orderLine)
            }).catch(() => {
                this.$refs.funnel.isAddingProduct = false
            })
        },
        reprint (orderLine) {
            const url = CONFIG.API.ROUTES.COMPLAINTS.REPRINT.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.$route.params.orderId)
            const payload = {
                complaint: this.draft,
                orderLine,
            }
            this.loading = true
            this.post(url, payload, 'Reprint completed!').then(({ data }) => {
                this.setOrder({
                    data: data,
                    currentOrderline: this.$route.params.orderLineId,
                })
                this.clearPreset()
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
            })
        },
        clearPreset () {
            this.draft = {}
            this.showReprintDialog = false
            this.draftOrderLine = null
        },
    },
    created () {
        this.orderCountry = this.order.countryCode ? this.order.countryCode.toUpperCase() : ''
    },
}
</script>

<style lang="scss" scoped>
    /deep/ .ag-theme-balham .ag-row.ag-row-last {
        border-bottom-width: 0 !important;
    }
    .funnel-dialog {
        min-width: 70vw;
        min-height: 90vh;
    }
    /deep/ .ag-row.attached-complaint {
        border-top: none;
    }
</style>
