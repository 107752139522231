<template>
    <mercur-card class="c-edit-complaint">
        <form data-e2e="orderlineEditComplaintForm" @submit.prevent="submitForm">
            <h3 class="font-weight-normal">Complaint Registered <status-chip class="c-edit-complaint__status" v-if="Object.keys(form).length">{{ form.complaintStatus }}</status-chip></h3>
            <p class="m-0">Registration date: {{ form.dateCreated }}</p>
            <p class="mb-2 mt-0">Last update date: {{ form.dateUpdated }}</p>
            <div class="row">
                <div class="col-sm-6">
                    <mercur-select v-model="form.complaintDepartment" :disabled="isLocked" data-e2e="orderlineEditComplaintComplaintDepartmentSelector" :class="getValidationClass(combinedValidation, 'complaintDepartment')">
                        <template #label>Complaint Department</template>
                        <option v-for="(val, key) in items" :value="key" :key="key">{{ val.title }}</option>
                        <template #note>
                            <span class="form-error" v-if="!combinedValidation.form.complaintDepartment.required">Department is required</span>
                        </template>
                    </mercur-select>
                </div>
                <div class="col-sm-6" v-if="form.complaintDepartment">
                    <template v-if="form.complaintDepartment === 'CUSTOMER'">
                        <pretty-select
                            :options="customerComplaintTypesList"
                            :multiple="true"
                            v-model="form.complaintType"
                            :disabled="isLocked"
                            placeholder="Complaint Type"
                        ></pretty-select>
                    </template>
                    <template v-else>
                        <mercur-select data-e2e="orderlineEditComplaintComplaintTypeSelector" v-model="form.complaintType" :disabled="isLocked">
                            <template #label>Complaint Type</template>
                            <option v-for="(item, index) in items[form.complaintDepartment].items" :value="item" :key="index">{{ item }}</option>
                        </mercur-select>
                    </template>
                </div>
                <div class="col-sm-6" v-if="form.complaintDepartment === 'CUSTOMER'">
                    <mercur-select v-model="form.complaintSolution" :disabled="isLocked">
                        <template #label>Complaint Solution</template>
                        <option v-for="(solution) in complaintSolutions" :value="solution" :key="solution">{{ solution }}</option>
                    </mercur-select>
                </div>
                <div class="col-sm-6" v-if="form.complaintDepartment === 'SUPPLIER'">
                    <mercur-input min="0" max="100" v-model="form.supplierComplaintCostPercentage" :disabled="isLocked">
                        Supplier complaint cost percentage
                        <template #suffix>%</template>
                    </mercur-input>
                </div>
                <div class="col-sm-6" v-if="['CUSTOMER_SERVICE', 'ARTWORK_CHECK'].includes(form.complaintDepartment)">
                    <mercur-select v-model="form.respondent" :disabled="isLocked" :class="getValidationClass(combinedValidation, 'respondent')">
                        <template #label>Respondent</template>
                        <option v-for="agent in agents" :key="agent.accountId" :value="`${agent.firstName} ${agent.lastName}`">{{ agent.firstName }} {{ agent.lastName }}</option>
                        <template #note>
                            <span class="form-error" v-if="!combinedValidation.form.respondent.required">Respondent is required</span>
                        </template>
                    </mercur-select>
                </div>
            </div>
            <p class="c-edit-complaint__note">NOTE: Please communicate clearly so the supplier understands the situation</p>
            <mercur-textarea v-model="form.description" :disabled="isLocked" :class="getValidationClass(combinedValidation, 'description')">
                Complaint description
                <template #note>
                    <span class="form-error" v-if="!combinedValidation.form.description.required">Complaint description is required</span>
                </template>
            </mercur-textarea>
            <template v-if="form.complaintDepartment === 'CUSTOMER'">
                <complaint-cost
                    v-model="form.complaintCosts.value"
                    :$v="combinedValidation"
                    :disabled="isLocked"
                    :validation-field="['complaintCosts', 'value']"
                    :is-required="!combinedValidation.form.complaintCosts.value.required"
                />
            </template>
            <div class="mt-3" v-if="!isLocked">
                <file-dropper :uploadUrl="uploadUrl" @uploaded="appendUploaded" ref="dropper" @fileRemoved="removeFile"></file-dropper>
            </div>
            <div class="text-right mt-4">
                <mercur-button class="btn btn-raised btn-yellow" data-e2e="orderlineEditComplaintAddSolutionButton" v-if="form.complaintDepartment !== 'CUSTOMER'" @click.native.prevent="triggerAddCustomerSolution" :disabled="loading">Add customer solution</mercur-button>
                <mercur-button class="btn btn-raised ml-2" v-if="!isLocked" :disabled="loading" @click.native.prevent="triggerProcessComplaint">Process Complaint</mercur-button>
                <mercur-button class="btn btn-raised btn-danger ml-2" data-e2e="orderlineEditComplaintDeleteComplaintButton" @click.native.prevent="archive" :disabled="loading || isLocked">Delete complaint</mercur-button>
                <mercur-button type="submit" data-e2e="orderlineEditComplaintSaveButton" class="btn btn-raised btn-success ml-2"  v-if="hasPermission('updateComplaint')" :disabled="loading || isLocked">Save changes</mercur-button>
            </div>
        </form>
        <mercur-dialog :is-open.sync="isOpen">
            <h3 class="font-weight-normal mt-1">Are you sure you want to process this complaint?</h3>
            <p>This will trigger processing in the backend, and based on complaint department this complaint will either be approved or sent to supplier central.</p>
            <template #footer>
                <mercur-button class="btn" @click.native="isOpen = false" :disabled="loading">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click.native.prevent="submitComplaint" :disabled="loading">Proceed</mercur-button>
            </template>
        </mercur-dialog>
        <mercur-dialog data-e2e="orderlineEditComplaintAddSolutionModal" :is-open.sync="isCustomerSolutionDialog">
            <h3 class="mt-1 font-weight-normal">Add customer complaint solution</h3>
            <p>This will create a customer complaint connected to the selected complaint.</p>
            <form @submit.prevent="addCustomerSolution">
                <mercur-select v-model="customerComplaint.complaintSolution" data-e2e="orderlineEditComplaintAddSolutionModalComplaintSolution" :class="getValidationClass(combinedValidation, 'complaintSolution', combinedValidation.customerComplaint)">
                    <template #label>Complaint solution</template>
                    <option v-for="(solution) in complaintSolutions" :value="solution" :key="solution">{{ solution }}</option>
                    <template #note>
                        <span class="form-error" v-if="!combinedValidation.customerComplaint.complaintSolution.required">Required.</span>
                    </template>
                </mercur-select>
                <div class="mb-3" :class="getValidationClass(combinedValidation, 'complaintType', combinedValidation.customerComplaint)">
                    <pretty-select
                        :options="customerComplaintTypesList"
                        :multiple="true"
                        v-model="customerComplaint.complaintType"
                        placeholder="Complaint Type"
                    ></pretty-select>
                    <span class="form-error" v-if="!combinedValidation.customerComplaint.complaintType.required">Required.</span>
                </div>
                <complaint-cost
                    v-if="isForRefund"
                    class="mt-4"
                    v-model="customerComplaint.complaintCosts.value"
                    :$v="combinedValidation"
                    :validation-field="['complaintCosts', 'value']"
                    :is-required="!combinedValidation.customerComplaint.complaintCosts.value.required"
                    :custom-field="combinedValidation.customerComplaint"
                />
                <div class="text-right mt-5">
                    <mercur-button class="btn mr-1" @click.native="isCustomerSolutionDialog = false" :disabled="loading">Cancel</mercur-button>
                    <mercur-button data-e2e="orderlineEditComplaintAddSolutionModalAddCustomerSolutionButton" class="btn btn-primary" type="submit" :disabled="loading">Add customer solution</mercur-button>
                </div>
            </form>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import FileDropper from '@/components/utils/FileDropper'
import StatusChip from '@/components/utils/StatusChip'
import { mapActions, mapGetters, mapState } from 'vuex'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { setComplaintPayload, filterPayloadForCustomerComplaintSolution } from '@/utils/payloadHelper'
import items from '@/langs/complaints.json'
import complaintSolutions from '@/langs/complaintSolutions.json'
import ComplaintCost from '@/components/elements/complaints/ComplaintCost'
import { required, requiredIf } from 'vuelidate/lib/validators'
import PrettySelect from '@/components/utils/PrettySelect'
export default {
    name: 'EditComplaint',
    mixins: [FormMixin],
    components: { StatusChip, FileDropper, ComplaintCost, PrettySelect },
    data () {
        return {
            shareSupplierStatus: ['OPEN', 'NEW'],
            items: items,
            isCurrency: true,
            isOpen: false,
            draft: {},
            complaintSolutions,
            customerComplaint: {},
            isCustomerSolutionDialog: false,
        }
    },
    props: {
        form: {
            type: Object,
        },
        validations: {
            type: Object,
        },
        isLocked: {
            default: false,
        },
        agents: {
            default: () => [],
        },
        refundSolutions: {},
    },
    validations: {
        customerComplaint: {
            complaintCosts: {
                value: {
                    required: requiredIf(function () {
                        if (this.customerComplaint.complaintSolution && this.refundSolutions.includes(this.customerComplaint.complaintSolution.toLowerCase())) {
                            return true
                        }
                    }),
                },
            },
            complaintType: {
                required,
            },
            complaintSolution: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters('auth', ['user']),
        ...mapState('order', ['order', 'orderLine']),
        ...mapState('customer', ['customer']),
        combinedValidation () {
            return {
                ...this.validations,
                ...this.$v,
            }
        },
        uploadUrl () {
            if (!this.form) {
                return
            }
            return CONFIG.API.ROUTES.COMPLAINTS.UPLOAD_BULK.replace('{orderId}', this.$route.params.orderId).replace('{complaintId}', this.form.complaintId)
        },
        computedComplaintCost () {
            if (this.isCurrency) {
                return this.form.complaintCosts.value
            }

            return this.orderLine.orderLineTotals.subtotal * this.form.complaintCosts.value / 100
        },
        customerComplaintTypesList () {
            if (!this.orderLine || !this.orderLine.complaints || !Array.isArray(this.orderLine.complaints)) {
                return []
            }

            const complaints = this.orderLine.complaints.filter(e => e.parentComplaintId === this.form.parentComplaintId && e.complaintDepartment !== 'CUSTOMER')
            return complaints.map(e => e.complaintType)
        },
        isForRefund () {
            if (!this.customerComplaint.complaintSolution) {
                return false
            }

            return !!this.refundSolutions.includes(this.customerComplaint.complaintSolution.toLowerCase())
        },
    },
    methods: {
        ...mapActions('order', ['updateComplaintAttachments', 'removeComplaintAttachments', 'updateComplaint', 'fetchOrder', 'addComplaintToOrderLine']),
        appendUploaded ($event) {
            const payload = {
                shareWithSupplier: false,
                uploadedBy: this.user.accountId,
                fileName: $event.file.objectName,
                url: $event.file.url,
            }

            if (this.form.complaintDepartment === 'SUPPLIER') {
                payload.shareWithSupplier = true
            }

            this.$emit('uploaded', payload)
        },
        removeFile (objectName) {
            const url = CONFIG.API.ROUTES.COMPLAINTS.REMOVE_FILE
                .replace('{orderId}', this.order.orderId)
                .replace('{complaintId}', this.form.complaintId)
                .replace('{objectName}', objectName)

            this.addJob(url)
            this.$api.post(url, {}).then(({ data }) => {
                this.$emit('remove', objectName)
                this.$root.$emit('notification:global', {
                    message: 'File removed...',
                })
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },
        submitForm () {
            const action = CONFIG.API.ROUTES.COMPLAINTS.UPDATE.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.$route.params.orderId)
            let payload = JSON.parse(JSON.stringify(this.form))

            payload = this.handlePayload(payload)

            if (payload.complaintDepartment === 'CUSTOMER' || Array.isArray(payload.complaintType)) {
                payload.complaintType = payload.complaintType.join(', ')
            }
            this.callUpdate(action, setComplaintPayload(payload), false)
        },
        handlePayload (payload) {
            if (payload.complaintDepartment === 'CUSTOMER' && this.refundSolutions.includes(payload.complaintSolution.toLowerCase())) {
                payload.complaintCosts.value = this.computedComplaintCost
                payload.complaintCosts.currency = this.order.currency
            } else {
                delete payload.complaintCosts
            }

            return payload
        },
        callUpdate (action, payload, checkEnabled = true) {
            if (this.combinedValidation && this.combinedValidation.form) {
                this.combinedValidation.form.$touch()
            }
            if (this.combinedValidation.form && this.combinedValidation.form.$invalid) {
                return
            }

            this.submit(action, payload, checkEnabled).then((data) => {
                this.handleResponse(data)
                this.$root.$emit('notification:global', {
                    message: 'Complaint was updated',
                    type: 'success',
                })
            })
        },
        handleResponse (data) {
            this.$emit('updated', data.data.complaint)
            this.updateComplaint(data.data.complaint)
            this.fetchOrder({
                orderId: this.$route.params.orderId,
                currentOrderline: this.$route.params.orderLineId,
            })
            this.$set(this, 'isCurrency', true)
            this.$refs.dropper.resetFiles()
        },
        archive () {
            const action = CONFIG.API.ROUTES.COMPLAINTS.UPDATE.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.$route.params.orderId)
            const payload = JSON.parse(JSON.stringify(this.form))
            if (!Object.keys(payload.complaintCosts).length) {
                payload.complaintCosts = {
                    value: 0,
                    currency: 'EUR',
                    rawValue: 0,
                }
            }
            payload.complaintStatus = 'ARCHIVED'
            delete payload.dateShared
            delete payload.supplierComplaintCosts
            this.callUpdate(action, payload, false)
        },
        triggerProcessComplaint () {
            this.draft = JSON.parse(JSON.stringify(this.form))
            this.isOpen = true
        },
        triggerAddCustomerSolution () {
            this.customerComplaint = JSON.parse(JSON.stringify(this.form))
            this.customerComplaint.complaintCosts = {}
            this.customerComplaint.complaintDepartment = 'CUSTOMER'
            this.customerComplaint.complaintSolution = null
            this.customerComplaint.complaintType = JSON.parse(JSON.stringify(this.customerComplaintTypesList))
            this.isCustomerSolutionDialog = true
        },
        addCustomerSolution () {
            if (this.combinedValidation && this.combinedValidation.customerComplaint) {
                this.combinedValidation.customerComplaint.$touch()
            }

            if (this.combinedValidation.customerComplaint && this.combinedValidation.customerComplaint.$invalid) {
                return
            }

            const payload = {
                orderId: this.order.orderId,
                complaint: filterPayloadForCustomerComplaintSolution(this.customerComplaint),
            }
            if (this.refundSolutions.includes(payload.complaint.complaintSolution.toLowerCase())) {
                payload.complaint.complaintCosts.currency = this.order.currency
            } else {
                delete payload.complaint.complaintCosts
            }
            payload.complaint.complaintType = payload.complaint.complaintType.join(', ')
            const url = CONFIG.API.ROUTES.COMPLAINTS.ADD.replace('{accountId}', this.$route.params.accountId)
            this.submit(url, payload).then(({ data }) => {
                data.forEach(item => {
                    if (item.event === 'ComplaintWasUpdated') {
                        this.updateComplaint(item.response.data.complaint)
                        this.$emit('updated', item.response.data.complaint)
                    }

                    if (item.event === 'ComplaintWasAdded') {
                        this.addComplaintToOrderLine(item.response.data.complaint)
                    }
                })
                this.isCustomerSolutionDialog = false
                this.customerComplaint = {}
                this.$root.$emit('notification:global', {
                    message: 'Complaint customer solution was added',
                    type: 'success',
                })
            })
        },
        submitComplaint () {
            const url = CONFIG.API.ROUTES.COMPLAINTS.SUBMIT.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.$route.params.orderId)
            const payload = this.handlePayload(this.draft)
            if (payload.complaintDepartment === 'CUSTOMER') {
                const value = payload.complaintCosts.value
                if (value === '' || value === undefined) {
                    this.$root.$emit('notification:global', {
                        message: 'Complaint cost is required',
                        type: 'error',
                    })
                    return
                }
                payload.complaintType = payload.complaintType.join(', ')
            }

            this.loading = true
            this.post(url, payload, 'Complaint was processed').then((data) => {
                this.handleResponse(data)
                this.isOpen = false
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-edit-complaint {
        &__status {
            float: right;
        }

        &__note {
            font-size: 12px;
            font-weight: bold;
        }

        &__cost-summary {
            font-size: 16px;
            margin-top: 28px;

            &-vat {
                display: block;
                width: 100%;
                font-size: 0.8rem;
            }
        }
    }
</style>
