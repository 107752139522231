<template>
    <div class="row">
        <div class="col-12">
            <p class="mb-0">Orderline price:
                <strong><currency :value="orderLine.orderLineTotals.subtotal" :type="order.currency" /></strong>
                (with vat. <currency :value="orderLine.orderLineTotals.total" :type="order.currency"></currency>)
            </p>
        </div>
        <div class="col-6 d-flex align-items-end mb-3">
            <mercur-input type="number" step="any" min="0" :max="isCurrency ? 'any' : 100"
                          v-model.number="localValue" :disabled="disabled"
                          class="flex-grow-1 mr-1 mb-0"
                          :class="getValidationClass($v, validationField, customField ? customField : $v.form)">
                Complaint costs (without VAT)
                <template #note>
                    <span class="form-error" v-if="isRequired">Complaint costs is required</span>
                </template>
            </mercur-input>
            <mercur-tooltip>
                <template #label>Click to toggle between currency and percentage</template>
                <mercur-button class="btn btn-primary" @click.native.prevent="toggleCurrencyPercentage" :disabled="disabled">
                    {{ isCurrency ? order.currency : '%' }}
                </mercur-button>
            </mercur-tooltip>
        </div>
        <div class="col-6 d-flex align-items-end">
            <p class="c-edit-complaint__cost-summary mb-3">
                Complaint cost summary: <br>
                <strong>
                    <currency :value="computedComplaintCost" :type="order.currency"/>
                </strong>
                <span class="c-edit-complaint__cost-summary-vat">
                (with VAT.
                <strong>
                    <currency :value="vatComplaintCost" :type="order.currency"/>
                </strong>)
            </span>
            </p>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import { mapState } from 'vuex'

export default {
    name: 'ComplaintCost',
    mixins: [FormMixin],
    props: {
        disabled: {
            default: false,
        },
        $v: {
            required: true,
        },
        value: {},
        validationField: {},
        isRequired: {},
        customField: {
            default: null,
        },
    },
    data () {
        return {
            isCurrency: true,
        }
    },
    computed: {
        ...mapState('order', ['order', 'orderLine']),
        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
        computedComplaintCost () {
            if (this.isCurrency) {
                return this.localValue
            }

            return this.orderLine.orderLineTotals.subtotal * this.localValue / 100
        },
        vatComplaintCost () {
            if (this.isCurrency) {
                return this.localValue * (1 + this.orderLine.vat)
            }
            return this.orderLine.orderLineTotals.total * this.localValue / 100
        },
    },
    methods: {
        toggleCurrencyPercentage  () {
            this.$set(this, 'localValue', null)
            this.$set(this, 'isCurrency', !this.isCurrency)
        },
    },
}
</script>
