<template>
    <div class="mb-5">
        <div class="row" v-if="order && Object.keys(order).length">
            <div class="col">
                <complaints-list class="c-card" @edit="setComplaint" :refund-solutions="refundSolutions" v-if="hasPermission('addComplaint')" :agents="agents"></complaints-list>
            </div>
        </div>
        <div class="row" v-if="Object.keys(form).length">
            <div class="col-6">
                <edit-complaint :form="form" :validations="$v" :agents="agents" :refund-solutions="refundSolutions" @uploaded="attachFile" @remove="removeFile" :isLocked="isLocked" @updated="setComplaint"></edit-complaint>
            </div>
            <div class="col-6">
                <complaint-attachments :form="form" :isLocked="isLocked"></complaint-attachments>
                <complaint-conversation v-if="Object.keys(form).length" :$v="$v" :isLocked="isLocked" :form="form" @updated="setComplaint"></complaint-conversation>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import ComplaintsList from '@/components/elements/order/ComplaintsList'
import EditComplaint from '@/components/elements/order/EditComplaint'
import ComplaintConversation from '@/components/elements/order/ComplaintConversation'
import ComplaintAttachments from '@/components/elements/order/ComplaintAttachments'
import { transformObjectEmptyArraysToEmptyObjects } from '@/utils/helpers'
import { required, requiredIf } from 'vuelidate/lib/validators'
import CONFIG from '@root/config'

export default {
    name: 'OrderLineComplaints',
    components: { EditComplaint, ComplaintsList, ComplaintAttachments, ComplaintConversation },
    data () {
        return {
            loadingComplaint: false,
            form: {},
            agents: [],
            refundSolutions: ['refund (fully)', 'refund (partly)', 'refund, cost difference'],
        }
    },
    computed: {
        ...mapState('order', ['order', 'orderLine']),
        isLocked () {
            const lockedStatuses = ['AWAITING_SUPPLIER', 'ARCHIVED', 'APPROVED', 'REPRINTED']
            return lockedStatuses.includes(this.form.complaintStatus)
        },
    },
    validations: {
        form: {
            complaintCosts: {
                value: {
                    required: requiredIf(function () {
                        if (this.form && this.form.complaintDepartment === 'CUSTOMER' && this.form.complaintSolution && this.refundSolutions.includes(this.form.complaintSolution.toLowerCase())) {
                            return true
                        }
                    }),
                },
            },
            description: {
                required,
            },
            complaintDepartment: {
                required,
            },
            respondent: {
                required: requiredIf(function () {
                    const arr = ['Customer Service', 'Manual Artwork Check']
                    return arr.includes(this.form.complaintDepartment)
                }),
            },
        },
    },
    methods: {
        ...mapActions('order', ['fetchComplaints']),
        setComplaint (complaint) {
            const parsedComplaint = JSON.parse(JSON.stringify(complaint))
            transformObjectEmptyArraysToEmptyObjects(parsedComplaint)
            if (complaint.complaintDepartment === 'CUSTOMER') {
                if (!parsedComplaint.complaintType) {
                    parsedComplaint.complaintType = []
                } else {
                    parsedComplaint.complaintType = parsedComplaint.complaintType.split(', ')
                }
            }
            this.$set(this, 'form', parsedComplaint)
        },
        attachFile (data) {
            if (typeof this.form.complaintAttachments === 'object' && !this.form.complaintAttachments.length) {
                this.$set(this.form, 'complaintAttachments', [])
            }
            this.form.complaintAttachments.push(data)
        },
        removeFile (name) {
            const index = this.form.complaintAttachments.findIndex(item => item.fileName === name)
            this.form.complaintAttachments.splice(index, 1)
        },
    },
    created () {
        const url = CONFIG.API.ROUTES.AGENTS.OVERVIEW
        const payload = { 'request': { 'startRow': 0, 'endRow': 1000, 'rowGroupCols': [], 'valueCols': [], 'pivotCols': [], 'pivotMode': false, 'groupKeys': [], 'filterModel': {}, 'sortModel': [] } }
        this.post(url, payload).then((data) => {
            this.agents = data.data
        })
    },
}
</script>
