<template>
    <div>
        <mercur-card class="mb-4">
            <h3 class="font-weight-normal">Uploads</h3>
            <div v-if="form.complaintAttachments && Array.isArray(form.complaintAttachments)">
                <table class="table">
                    <thead>
                        <tr>
                            <th>File</th>
                            <th>Share with supplier</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="attachment in form.complaintAttachments" :key="attachment.fileName">
                            <td>
                                <span class="attachment" @click.prevent="getDownloadUrl(attachment.fileName)"><span v-if="attachment.fileName">{{attachment.fileName}}</span><span v-else>attachment</span></span>
                            </td>
                            <td>
                                <mercur-checkbox v-model="attachment.shareWithSupplier" :disabled="isLocked" :id="attachment.fileName">Share</mercur-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else-if="!Object.keys(form.complaintAttachments).length">There are no attachments for this complaint.</div>
        </mercur-card>
    </div>
</template>

<script>

import CONFIG from '@root/config'

export default {
    name: 'ComplaintAttachments',
    props: {
        form: {
            type: Object,
        },
        isLocked: {
            default: false,
        },
    },
    methods: {
        getDownloadUrl (objectName) {
            const url = CONFIG.API.ROUTES.COMPLAINTS.GET_DOWNLOAD_URL
                .replace('{orderId}', this.$route.params.orderId)
                .replace('{complaintId}', this.form.complaintId)
                .replace('{objectName}', objectName)

            this.addJob(url)
            this.$root.$emit('notification:global', {
                message: 'Downloading file...',
            })
            this.$api.get(url).then(({ data }) => {
                window.open(data.url, '_blank')
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .attachment {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
